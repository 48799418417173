import Tagger from '@elbwalker/tagger';
import type { WalkerOS } from '@elbwalker/types';
import Elbwalker, { elb } from '@elbwalker/walker.js';
import Cookie from 'js-cookie';

import { getGaClientId, getGaSessionInformation } from './ga';

import { config } from 'lib/config/client';

const WALKER_PREFIX = 'data-elb';
const ONE_TRUST_CONSENT_COOKIE = 'OptanonConsent';

export const tagger = Tagger({
  prefix: WALKER_PREFIX,
});

const getBusinessFlow = (pathname: string | undefined): string => {
  if (pathname?.includes('login')) {
    return 'login';
  }

  if (pathname?.includes('create')) {
    return 'signup';
  }

  if (pathname?.includes('authentication')) {
    return 'challenges';
  }

  return 'unknown';
};

// This function is used to parse the consent from the OneTrust cookie.
// This cookie is currently set by other apps under the .sumup.com domain that have a cookie banner.
// Since we don't want to render a cookie banner in SSO, we rely on the cookie being set by other apps.
export const getUserConsent = (): WalkerOS.Consent => {
  const consent = Cookie.get(ONE_TRUST_CONSENT_COOKIE) as string;

  if (!consent) {
    return {};
  }

  const consentParams = new URLSearchParams(consent);
  const groups = consentParams.get('groups');

  return {
    strictly_necessary: !!groups?.includes('C0001:1'),
    performance: !!groups?.includes('C0002:1'),
    functional: !!groups?.includes('C0003:1'),
    targeting: !!groups?.includes('C0004:1'),
    social_media: !!groups?.includes('C0005:1'),
  };
};

const parseDestinationEvent = (event: WalkerOS.Event) => {
  const eventName = event.event.replace(' ', '_').toLowerCase();
  const pageLocation = event?.data.id || window?.location?.pathname;
  return {
    client_id: getGaClientId(),
    events: [
      {
        name: eventName,
        params: {
          // business_flow is sent first so it can be overriden by the event data
          business_flow: getBusinessFlow(event.data.id as string),
          ...event.globals,
          ...event.data,
          page_location: pageLocation,
          event: eventName,
          event_timestamp_msec: event.timestamp,
          // Hardcoded flag as all client side events have consent when sent to the destination.
          consent: true,
        },
      },
    ],
  };
};

const destinationMeasurementProtocol = {
  config: {
    consent: {
      performance: true,
    },
  },

  push(event: WalkerOS.Event) {
    const destinationEvent = parseDestinationEvent(event);

    fetch('https://collect.sumup.com/measure', {
      method: 'POST',
      keepalive: true,
      mode: 'no-cors',
      body: JSON.stringify(destinationEvent),
    }).catch(() => {
      // Fail gracefully
    });
  },
};

const destinationLog = {
  config: {
    consent: {
      performance: true,
    },
  },

  push(event: WalkerOS.Event) {
    const destinationEvent = parseDestinationEvent(event);
    console.log('Analytics event:', destinationEvent);
  },
};

interface InitAnalyticsParams {
  /**
   * The page locale.
   */
  locale: string;
  /**
   * The Google Analytics client_id for the session.
   */
  clientId: string;
  /**
   * The real IP address of the user.
   */
  userIp: string;
}

export function initAnalytics({
  locale,
  clientId,
  userIp,
}: InitAnalyticsParams) {
  if (!window?.walkerjs) {
    window.walkerjs = Elbwalker({
      globalsStatic: {
        app: 'auth',
        business_feature: 'identity',
        html_language: locale?.toLowerCase(),
        user_agent: navigator.userAgent,
        internal_origin: clientId,
        user_ip: userIp,
        ...getGaSessionInformation(),
        // Hardcoded flag as all client side events have consent when sent to the destination.
        consent: true,
      },
      prefix: WALKER_PREFIX,
      consent: getUserConsent(),
    });

    // The measurement protocol tracking is only enabled for production environments.
    if (config.enableAnalytics) {
      elb('walker destination', destinationMeasurementProtocol);
    } else {
      elb('walker destination', destinationLog);
    }
  }
}
